import * as React from "react";
import s from "./Layout.module.css";

type Props = {|
  children: React.Node
|};

function Layout({ children }: Props) {
  return <div className={s.root}>{children}</div>;
}

export default Layout;
