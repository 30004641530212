const defaultOptions = {
  markdown: "",
  backgroundColor: "#fff",
  scale: 1,
  padding: {
    vertical: 12,
    horizontal: 12
  },
  code: {
    theme: "monokai",
    shadow: {
      horizontal: 2,
      vertical: 4,
      blur: 8,
      color: "#000",
      transparent: 0.55
    },
    borderRadius: 4
  }
};

export type OptionsType = {
  markdown: string,
  backgroundColor: string,
  scale: number,
  padding: {
    vertical: number,
    horizontal: number
  },
  code: {
    theme: string,
    shadow: {
      horizontal: number,
      vertical: number,
      blur: number,
      color: string,
      transparent: number
    },
    borderRadius: number
  }
};

export default defaultOptions;
