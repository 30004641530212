// @flow

import * as React from "react";

type Props = {|
  children: React.Node,
  separator: React.Node
|};

function Join({ children, separator }: Props) {
  return React.Children.toArray(children)
    .filter(Boolean)
    .map<React.Node>((child, index) => {
      return (
        <React.Fragment key={index}>
          {index !== 0 && separator}
          {child}
        </React.Fragment>
      );
    });
}

export default Join;
