export const PREVIEW_ELEMENT_ID = "preview";

export const instruction = `# m/\\rker ![marker](https://img.shields.io/badge/m%2F%5Crker-frontend-green)

Simple markdown editor with preview.

## Features

- Open and save markdown file
- Download privew image.
- Share it with copy and past URL.
- [API](https://github.com/petersolopov/marker-server#api) \`POST /api/image/\` and \`POST /api/html/\`.
- Code looks great:

\`\`\`js
/* sum function */
function sum(a, b) {
  return a + b;
}
\`\`\`

## Limitations

- Preview don't support a HTML markup.
- API has limit with 1000 character.
`;
