import React, { useCallback, useState } from "react";

import Join from "components/Join";
import Clickable from "components/Clickable";
import s from "./Menu.module.css";

import type { OptionsType } from "constants/defaultOptions";
import { takeScreenshoot } from "./Menu.utils";
import { PREVIEW_ELEMENT_ID } from "routes/Home/Home.constants";
import { downloadText } from "components/Menu/Menu.utils";

type Props = {|
  options?: OptionsType,
  onOpenFile?: string => void
|};

function Menu(props: Props) {
  const [fileInputValue, setFileInputValue] = useState("");
  const spacer = <span className={s.spacer}>·</span>;
  const { options, onOpenFile } = props;

  const handleDownload = useCallback(() => {
    if (!options) {
      return;
    }
    takeScreenshoot(PREVIEW_ELEMENT_ID, options);
  }, [options]);

  const handleOpenFile = useCallback(
    async e => {
      const file = e.target.files[0];
      if (!file) {
        return;
      }

      const text = await file.text();

      if (onOpenFile) {
        onOpenFile(text);
      }

      setFileInputValue("");
    },
    [setFileInputValue, onOpenFile]
  );

  const handleSaveMd = useCallback(() => {
    if (!options) {
      return;
    }
    downloadText("marked.md", options.markdown);
  }, [options]);

  return (
    <menu className={s.root}>
      <div>
        <Join separator={spacer}>
          <a href="/" title="home">
            home
          </a>
          <span>
            <input
              value={fileInputValue}
              className={s.fileinput}
              type="file"
              name="file"
              id="file"
              accept=".md,.txt"
              onChange={handleOpenFile}
            />
            <label className={s.action} htmlFor="file">
              open file
            </label>
          </span>
          <Clickable className={s.action} onClick={handleSaveMd}>
            save markdown
          </Clickable>
        </Join>
      </div>
      <Clickable className={s.action} onClick={handleDownload}>
        save preview image
      </Clickable>
    </menu>
  );
}

export default Menu;
