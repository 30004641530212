import * as React from "react";
import s from "./Clickable.module.css";

type Props = {
  children: React.Node,
  className?: string,
  ...
};

function Clickable({ children, className, ...otherProps }: Props) {
  const classNames = className ? `${className} ${s.button}` : s.button;
  return (
    <button className={classNames} {...otherProps}>
      {children}
    </button>
  );
}

export default Clickable;
