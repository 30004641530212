// @flow

import React, { useState, useCallback, useRef } from "react";

import Editor from "components/Editor";
import Preview from "components/Preview";
import Menu from "components/Menu";
import Layout from "components/Layout";

import { getOptionsFromLocation, setOptionsToLocation } from "./Home.utils";
import { PREVIEW_ELEMENT_ID } from "./Home.constants";
import s from "./Home.module.css";

function Home() {
  const [options, setOptions] = useState(getOptionsFromLocation());
  const editorRef = useRef(null);

  const handleInitEditor = useCallback(editor => {
    editorRef.current = editor;
  }, []);

  const handleEditorChange = useCallback(
    markdown => {
      setOptions(oldOptions => {
        const newOptions = {
          ...oldOptions,
          markdown
        };

        setOptionsToLocation(newOptions, true);

        return newOptions;
      });
    },
    [setOptions]
  );

  const handleOpenFile = useCallback(
    text => {
      if (!editorRef.current) {
        return;
      }

      handleEditorChange(text);
      /* $FlowIssue */
      editorRef.current.setValue(text);
    },
    [handleEditorChange]
  );

  const { markdown } = options;

  return (
    <Layout>
      <Menu options={options} onOpenFile={handleOpenFile} />
      <div className={s.main}>
        <div className={s.editor}>
          <Editor
            initialValue={markdown}
            onChange={handleEditorChange}
            onInit={handleInitEditor}
          />
        </div>
        <div className={s.preview}>
          <Preview options={options} id={PREVIEW_ELEMENT_ID} />
        </div>
      </div>
    </Layout>
  );
}

export default Home;
