import React from "react";
import Menu from "components/Menu";
import Layout from "components/Layout";

function NoMatch() {
  return (
    <Layout>
      <div
        style={{
          borderRadius: 4,
          border: "1px solid #ddd",
          marginBottom: 8
        }}
      >
        <Menu />
      </div>
      page is not found
    </Layout>
  );
}

export default NoMatch;
