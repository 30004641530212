import { instruction } from "./Home.constants";
import defaultOptions from "constants/defaultOptions";

import type { OptionsType } from "constants/defaultOptions";

export const getOptionsFromLocation = () => {
  const hash = window.location.hash.slice(1);

  if (hash) {
    try {
      return JSON.parse(decodeURIComponent(escape(window.atob(hash))));
    } catch (e) {
      return { ...defaultOptions, markdown: "sorry, url is broken" };
    }
  }

  return { ...defaultOptions, markdown: instruction };
};

export const setOptionsToLocation = (
  options: OptionsType,
  isPush?: boolean
) => {
  const encoded = window.btoa(
    unescape(encodeURIComponent(JSON.stringify(options)))
  );
  const method = isPush ? "pushState" : "replaceState";

  window.history[method](
    encoded,
    "mti",
    `${window.location.pathname}#${encoded}`
  );
};
